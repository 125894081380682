.ancora__inicio-area{
    display: flex;
    flex-direction: column;
    font-size: 14px;
    text-align: center;
    overflow-wrap: break-word;
    width: 150px;
    text-decoration: none;
    color: #F0A500;
    padding: 0.5em;
    margin-top: 2em;
    margin-bottom: 1em;
}

.ancora__div{
    display: flex;
    justify-content: center;
}

.ancora__inicio-area:hover{
    border-radius: 10px;
    background-color: rgb(4, 13, 18, 0.3);
}