.redirect__contato{
    font-size: 20px;
    border: 1px solid #F0A500;
    color: #F0A500;
    border-radius: 20px;
    padding: 1em;
    margin-right: 10vw;
    text-decoration: none;
}

.redirect__contato.poshg{
    padding: 1em 2em;
    margin-right: 0;
    margin-top: 1em;
}

.redirect__contato:hover{
    background-color: #F0A500;
    color: black;
    cursor: pointer;
    transition: 0.5s;
}

@media screen and (max-width:450px){
    .redirect__contato{
        margin-right: 0;
    }
}