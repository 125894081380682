.areaModeloContainer {
    color: #F0A500;
    position: relative;
    display: flex;
    flex-direction: column;

}

.titulo {
    font-family: var(--fonte-secundaria);
    padding: 0 7.5rem;
    font-size: 4rem;
    display: flex;
    align-items: center;
    height: 40px;
}

.areaConteudoContainer {
    padding: 2.5rem 7.5rem 4.5rem;
    font-size: 24px;
}

@media (max-width: 1024px){
    .areaModeloContainer {
        align-items: center;
    }
} 

@media (max-width: 744px) {

    .titulo {
        padding: 0 1rem;
        font-size: 2rem;
        height: 104px;
    }

    .areaConteudoContainer {
        padding: 2rem 1.5rem 1.5rem;
    }

    .areaModeloContainer {
        align-items: center;
    }
}
