.header{
    background-color: #040D12;
    height: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3em;
    border-bottom: 1px solid #F0A500;
    font-family: var(--fonte-principal);
    font-weight: 700;
}

.logo{
    margin-left: 10vw;
}

.logo__img{
    width: 250px;
    height: 180px;
}

@media screen and (max-width:1080px){

    .logo{
        margin-left: 4em;
    }

    .logo__img{
        width: 180px;
        height: 130px;
    }
}

@media screen and (max-width:450px){
    .header{
        flex-direction: column;
        height: 280px;
        width: 100vw;
        justify-content:start;
        align-items: center;
    }

    .logo{
        margin-left: 0;
        margin-bottom: 1em;
    }

    .logo__img{
        width: 240px;
        height: 160px;
    }
}