.contatos{
    border-top: 1px solid #F0A500;
    font-family: var(--fonte-principal);
    font-weight: 600;
    display: flex;
    justify-content: center;
    margin-bottom: 3em;
    flex-direction: column;
    align-items: center;
}

.cards{
    display: flex;
    flex-direction: row;
}

.card__contato{
    display: flex;
    flex-direction: column;
    background-color: rgb(4, 13, 18, 0.3);
    border-radius: 20px;
    width: 350px;
    margin: 3em 0.5em 3em 0.5em;
    box-shadow: 5px 5px 20px black;
}

.card__contato:hover{
    margin-top: 2em;
    margin-bottom: 4em;
    transition: 0.5s;
}

.ancora__contato{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: #F0A500;
    margin-bottom: 1em;
    margin-left: 1.2em;
}

.ancora__contato:hover{
    text-decoration: underline;
}

.ancora__contato img{
    width: 48px;
    height: 48px;
}

.logo__contato{
    margin-right: 0.5em;
}

.titulo__contato{
    margin-top: 1.5em;
    font-size: 42px;
    align-self: center;
    color: #F0A500;
}

.descricao__contato{
    color: #F0A500;
    align-self: center;
}

.ancora__inicio{
    text-align: center;
    overflow-wrap: break-word;
    width: 130px;
    text-decoration: none;
    color: #F0A500;
    padding: 0.5em;

}

.ancora__inicio:hover{
    border-radius: 10px;
    background-color: rgb(4, 13, 18, 0.3);
}

.nome__contato{
    color: #F0A500;
    text-align: center;
}

.qrcode{
    width: 128px;
    height: 128px;
    margin-bottom: 1em;
}

.anchor{
    align-self: center;
}

@media screen and (max-width:450px){

    .titulo__contato{
        font-size: 32px;
    }
    
    .cards{
        flex-direction: column;
    }

    .card__contato{
        margin-left: 0;
        margin-right: 0;
    }
}