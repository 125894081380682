.descricao{
    color: #F0A500;
    text-align: center;
    width: 100vw;
    padding: 1em 15em 1em 8em;
    font-size: 20px;
}

.card{
    font-family: var(--fonte-principal);
    font-weight: 600;
    padding: 1em 4.5em 1em 4.5em;
    width: 100vw;
    display: flex;
    align-items: center;
    background-color: #070F2B;
}

.imagem__card{
    margin-left: 1em;
    max-width: 100%;
    max-height: 100%;
    border-radius: 400px;
    border: 2px solid #F0A500;
    opacity: 100%;
}

@media screen and (max-width:1600px){
    .imagem__card{
        max-width: 30%;
    }
    .descricao{
        padding: 1em 12em 1em 6em;
    }
}

@media screen and (max-width:1080px){
    .card{
        flex-direction: column;
        padding: 0;
    }

    .imagem__card{
        margin-left: 0;
        max-width: 80%;
        max-height: 80%;
    }

    .descricao{
        width: 80vw;
        margin-left: 0;
        padding: 0;
        margin-bottom: 3em;
    }
}

@media screen and (max-width:450px){
    .card{
        flex-direction: column;
        padding: 0;
    }

    .imagem__card{
        margin-left: 0;
        max-width: 80%;
        max-height: 80%;
    }

    .descricao{
        width: 80vw;
        margin-left: 0;
        padding: 0;
        margin-bottom: 3em;
    }

}